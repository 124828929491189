import React from "react";
import CaseLawCategoriesList from "./CaseLawCategoriesList";
import {useTranslation} from "gatsby-plugin-react-i18next";

const CaseLawsContent = ({nationalCourtCaseLaw, country}:any) => {
    const {t} = useTranslation()
    return (
        <>
            {/*<CaseLawsHeader />*/}

            <div className="container">
                <div className="row">
                    <CaseLawCategoriesList data={nationalCourtCaseLaw} country={country} type='NationalCourtCaseLaw' title={t('National Court Case Law Categories')} />
                </div>
            </div>
        </>
    )
}

export default CaseLawsContent
