import React, {useEffect, useState} from "react";
import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import CaseLawsContent from "../../components/caseLaw/CaseLawsContent";
import {getCaseLawSummaryCategories, getNationalCourtCaseLawCategories} from "../../queries";


const CaseLawCategorie = ({country}: any) => {
    const {t, i18n} = useTranslation()
    const [nationalCourtCaseLaw, setNationalCourtCaseLaw] = useState<any>(null);

    useEffect(() => {
        getNationalCourtCaseLawCategories(country, setNationalCourtCaseLaw, i18n.language)
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    }, [])

    return (
        <>
            <Helmet>
                <title>OHADA | {t('ohadarbCaseLaw')}</title>
            </Helmet>
            <Layout title={`OHADARB | ${country} - National Court Case Law Category`}>
                <CaseLawsContent nationalCourtCaseLaw={nationalCourtCaseLaw} country={country}  />
            </Layout>
        </>
    )
}

export default CaseLawCategorie;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
