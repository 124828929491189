import React from 'react'

export const MessageModal = ({message, linkCopied}: any) => {


    if(typeof window === "object") {
        const linkCop = document.getElementById('linkCopied');
        if (linkCop && linkCopied) {
            linkCop.addEventListener('click', () => {
                navigator.clipboard.writeText(linkCop.textContent || '');
            })
            let selection = window?.getSelection();
            let range = document.createRange();
            range.selectNodeContents(linkCop);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    }

    return (
        <div className="modal fade" id="messageModal" tabIndex={-1} role="dialog" aria-labelledby="messageModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content text-center">
                    <div className="modal-body p-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        <h2 className="mb-3 text-start"></h2>
                        <h3>{message}</h3>
                        <div className="d-flex flex-column justify-content-center">
                            {linkCopied &&
                                <code
                                    style={{maxWidth: '90%', overflowX: 'scroll', scrollbarWidth: 'none', textOverflow: 'ellipsis', alignSelf: 'center'}}
                                    id="linkCopied" className="text-nowrap lead mb-1 m-1 text-center p-2 bg-pale-ash rounded-4">
                                    {linkCopied}
                                </code>
                            }
                            <i className="m-0 p-0 fs-70 uil uil-check-circle text-green"></i>
                        </div>


                    </div>
                    {/*    send by mail */}
                    {/*    <div className="d-flex flex-row justify-content-between mx-4">*/}
                    {/*        <button className="btn btn-ash rounded-pill btn-login w-70 mb-2"*/}
                    {/*                data-bs-dismiss="modal"*/}
                    {/*                aria-label="Close"*/}
                    {/*        >*/}
                    {/*            Cancel*/}
                    {/*        </button>*/}
                    {/*        <button className="btn btn-danger rounded-pill btn-login w-70 mb-2"*/}
                    {/*                data-bs-dismiss="modal"*/}
                    {/*                aria-label="Close"*/}
                    {/*                onClick={() => {*/}
                    {/*                    deleteFunction(id)*/}
                    {/*                }}*/}
                    {/*        >*/}
                    {/*            Delete*/}
                    {/*        </button>*/}
                    {/*    </div>*/}

                </div>
            </div>
        </div>
    )
}
