import React, {useEffect, useState} from 'react'
import {
    postNewCaseLawSummaryCategory,
    postNewCategory, postNewNationalCourtCaseLawCategory,
    putNewCaseLawSummaryCategory,
    putNewCategory, putNewNationalCourtCaseLawCategory
} from "../../queries";
import {useTranslation} from "gatsby-plugin-react-i18next";


export const AddCategory = ({updatedData = undefined, type, country}: {country: string, updatedData: any | undefined, type: string }) => {

    const [formStatus, setFormStatus] = useState(false);
    const {t, i18n} = useTranslation()
    const [catData, setCatData] = useState<any>({
        name: '',
        type: type
    } )
    const selectOption = [
        {
            name: t('Category'),
            value: 'Category',
            disabled: true,
        },
        {
            name: t('Case Law Summary'),
            value: 'CaseLawSummary',
        },
        {
            name: t('National Court Case Law'),
            value: 'NationalCourtCaseLaw'
        }
    ]
    useEffect(() => {
        updatedData !== undefined && setCatData(updatedData)
        return () => {
            setCatData({
                name: ''
            })
        }
    }, [updatedData])
    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (catData?.name === '') {
            alert(t('Please fill name the fields'));
            return;
        }

        if (catData.id) {
            console.log(catData)

            type === 'Category' && putNewCategory({id: catData.id, name: catData.name,
                lang: i18n.language}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category updated successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
            type === 'CaseLawSummary' && putNewCaseLawSummaryCategory({id: catData.id, name: catData.name,
                lang: i18n.language}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category updated successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
            type === 'NationalCourtCaseLaw' && putNewNationalCourtCaseLawCategory({id: catData.id, name: catData.name,
                lang: i18n.language, country}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category updated successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
        }else {
            type === 'Category' && postNewCategory({name: catData.name, type: catData.type,
                lang: i18n.language}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category added successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
            type === 'CaseLawSummary' && postNewCaseLawSummaryCategory({name: catData.name, type: catData.type,
                lang: i18n.language}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category added successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
            type === 'NationalCourtCaseLaw' && postNewNationalCourtCaseLawCategory({name: catData.name, type: catData.type,
                lang: i18n.language, country}, (res: any) => {
                if (res.id) {
                    setFormStatus(true);
                    // alert('Category added successfully')
                    window.location.reload();
                } else {
                    alert(t('some error handle on server'))
                }
            })
        }

    }


    return (
        <div>
            <div className="modal fade" id="modal-addCategory" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-auto">
                    <div className="modal-content text-center">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <h2 className="mb-3 text-start">{t('Add / Edit')} {selectOption.filter(x => x.value === type)?.[0]?.name}</h2>
                            <p className="lead mb-6 text-start">{t('Add / Edit')} {t('takes less than 5 minutes.')}</p>
                            <form className="text-start mb-3"
                                  acceptCharset="UTF-8"
                                  method="POST"
                                  encType="multipart/form-data"
                                  id="gatsbyForm"
                                  onSubmit={handleSubmit}
                            >
                                <div className="form-floating mb-4">
                                    <input type="text"
                                           className="form-control"
                                           placeholder="Name"
                                           value={catData?.name || updatedData?.name  || ''}
                                           autoFocus
                                           onChange={(e) => {
                                               setCatData({
                                                   ...catData,
                                                   name: e.target.value
                                               })
                                           }}
                                           id="name"
                                           name="name"
                                    />
                                    <label htmlFor="title">Name</label>
                                </div>
                                <div className="form-select-wrapper mb-4">
                                    <select className="form-select" name="type"
                                            required
                                            disabled={type === 'Category'}
                                            aria-label="Select a type"
                                            onChange={(e) => {
                                                setCatData({
                                                    ...catData,
                                                    type: e.target.value
                                                })
                                            }}
                                            value={catData?.type}
                                    >
                                        {selectOption.map((item, id: number) => {
                                            return (
                                                <option
                                                    key={id}
                                                    disabled={item?.disabled}
                                                    value={item.value}>
                                                    {item.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                {formStatus ? (
                                    <div className="text-success mb-2">
                                        Your Case law Category has been added.
                                    </div>
                                ) : (
                                    ""
                                )}
                                <button className="btn btn-primary rounded-pill btn-login w-100 mb-2" type="submit">
                                    {t('Save')}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
