import React, {useState} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {navigate} from "@reach/router";
import {AddCategory} from "../CCJACaseLaw/addCategory";
import {getConnected} from "../../util/helper";
import {deleteCaseLawSummaryCategory, deleteCategory, deleteNationalCourtCaseLawCategory} from "../../queries";
import {DeleteModal} from "../modal/deleteModal";
import {MessageModal} from "../modal/messageModal";
import {initialCCJA, initialCaseLaw} from "../../util/type/types";
import {AddCcjaLaw} from "../modal/addCcjaLaw";

const CaseLawCategoriesList = ({title, type, country='', data}: { title: string, type: string, country: string, data: any }) => {

    let connected = getConnected()
    const [user, setUser] = useState(connected || null)
    const [selected, setSelected] = useState<any>(undefined)
    const isInCcjaCaseLaw = typeof window === 'object' && window?.location.pathname.includes('ccjaCaseLaw')
    const isInCaseLawSummaries = typeof window === 'object' && window?.location.pathname.includes('caseLawSummaries')
    const isInNationalCourtsCaseLaw = typeof window === 'object' && window?.location.pathname.includes('nationalCourtsCaseLaw')
    const {t} = useTranslation()
    const handleClick = (name: string, id: number) => {
        // const query = new URLSearchParams({cat: name})
        const path = `${location.pathname}${id}`
        //name.split(' ').join('-')
        navigate(path);
        return;
    }
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [linkCopied, setLinkCopied] = useState<string>('');
    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setLinkCopied(copyMe)
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
            setLinkCopied('');
        }
    };
    // useEffect(() => {
    //     setFilteredData(data.filter(
    //         (item: any) => type === 'ccjaCaseLaws' ? item.type === 'Category'
    //         : type === 'nationalCourtsCaseLawList' ? item.type === 'nationalCourtsCaseLaw'
    //                 : type === 'caseLawSummaries' && item.type === 'CaseLawSummary'))
    // }, [data, type]);

    return (
        <>
            <div className="col-xl-12 order-xl-2">
                <section id="ste" className="wrapper py-10">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <h2 className="mb-5">{title}</h2>
                        {user?.id && user?.roles[0].name === 'ADMIN' &&
                            <div className="">
                                <i className="uil uil-plus-circle text-primary p-2 fs-30 text-purple"
                                   data-bs-target="#modal-addCategory"
                                   data-bs-toggle="modal"
                                   onClick={() => setSelected(undefined)}
                                   style={{cursor: "pointer"}}
                                   data-toggle="tooltip" data-placement="bottom" title={t("Add New National Law")}
                                ></i>
                            </div>
                        }
                    </div>
                    <h6 className="widget-title fs-17 mb-2 ps-xl-5">{t('Choose an element')}</h6>
                    <div className="widget">
                        <form className="search-form">
                            <div className="form-floating mb-0">
                                <input id="search-form" type="text" className="form-control"
                                       placeholder="Search"/>
                                <label htmlFor="search-form">{t('Search')}</label>
                            </div>
                        </form>
                    </div>
                    <div className="widget shadow-lg p-2">
                        <nav className="ps-xl-5" id="sidebar-nav">
                            <ul className="list-unstyled fs-sm lh-sm text-reset overflow-auto"
                                style={{
                                    // maxWidth: '60px',
                                    maxHeight: '300px',
                                    scrollbarWidth: 'thin',

                                }}>
                                {data ? data
                                    ?.sort((a: { name: string; }, b: { name: string; }) => a.name > b.name ? 1 : -1)
                                    ?.map((cat: any, id: number) => {
                                        if (!cat?.subtype) {
                                            return (
                                                <li className="d-flex flex-row justify-content-between align-items-center
                                            fs-20 shadow-lg pe-auto hover-overlay"
                                                    onClick={() => {
                                                        setSelected(data.filter((item: { id: any; }) => parseInt(item.id) === cat.id)[0])
                                                    }}
                                                    key={id}
                                                    style={{cursor: "pointer"}}>
                                                    <a
                                                        className={`nav-link ${selected?.name === cat.name && 'active'}`}
                                                        onClick={() => handleClick(cat.name, cat.id)}
                                                    >
                                                        {cat.name}
                                                    </a>
                                                    {user?.id && user?.roles[0].name === 'ADMIN' &&
                                                        <div className="mask" style={{visibility: 'visible'}}>
                                                            <i className="uil uil-edit text-ash p-2"
                                                               style={{cursor: "pointer"}}
                                                               data-bs-target="#modal-addCategory"
                                                               data-bs-toggle="modal"
                                                               data-toggle="tooltip" data-placement="bottom"
                                                               title={t("Edit")}></i>
                                                            <i className="uil uil-trash-alt text-ash hover:text-back p-2"
                                                               style={{cursor: "pointer"}}
                                                               data-bs-target="#modal-delete"
                                                               data-bs-toggle="modal"
                                                               data-toggle="tooltip" data-placement="bottom"
                                                               title={t("delete")}></i>
                                                            <i className="uil uil-external-link-alt text-ash p-2"
                                                               style={{cursor: "pointer"}}
                                                               data-bs-target="#messageModal"
                                                               data-bs-toggle="modal"
                                                               onClick={
                                                                   () => copyToClipBoard(`${window?.location.origin}${window?.location.pathname}${cat.id}`)
                                                               }
                                                               data-toggle="tooltip" data-placement="bottom"
                                                               title={t("Share")}></i>
                                                            <i className="uil uil-plus-circle text-primary p-2"
                                                               style={{cursor: "pointer"}}
                                                               data-bs-target="#modal-addCaseLaw0"
                                                               data-bs-toggle="modal"
                                                               data-toggle="tooltip" data-placement="bottom"
                                                               title={type === 'Category' ? t("Add New Case Law") : type === 'CaseLawSummary' ? t('Add New Case Law Summaries') : ""}></i>
                                                            {/*<i className="uil uil-plus-circle text-primary p-2"*/}
                                                            {/*   style={{cursor: "pointer"}}*/}
                                                            {/*   data-toggle="tooltip" data-placement="bottom"*/}
                                                            {/*   title="Add New Sub-Categorie"></i>*/}
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <div key={id} className="card plain accordion-item w-100 ">
                                                    <div className="card-header m-0 p-0 py-1"
                                                         id={`headingSimpleTwo${id}`}>
                                                        <li className="collapsed pe-auto hover-overlay d-flex flex-row
                                                    justify-content-between align-items-start fs-20 shadow-lg"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapseSimpleTwo${id}`}
                                                            aria-expanded="false"
                                                            aria-controls={`collapseSimpleTwo${id}`}>
                                                            <div className="nav-link"
                                                                 style={{cursor: 'pointer'}}>
                                                                {cat.name}
                                                            </div>
                                                            <div className="mask" style={{visibility: 'visible'}}>
                                                                <i className="uil uil-edit text-ash p-2"
                                                                   style={{cursor: "pointer"}}
                                                                   data-toggle="tooltip" data-placement="bottom"
                                                                   title={t("Edit")}></i>
                                                                <i className="uil uil-external-link-alt text-ash p-2"
                                                                   style={{cursor: "pointer"}}
                                                                   data-toggle="tooltip" data-placement="bottom"
                                                                   title={t("Share")}></i>
                                                                <i className="uil uil-plus-circle text-primary p-2"
                                                                   style={{cursor: "pointer"}}
                                                                   data-bs-target="#modal-addCaseLaw0"
                                                                   data-bs-toggle="modal"
                                                                   data-toggle="tooltip" data-placement="bottom"
                                                                   title={type === 'Category' ? t("Add New Case Law") : type === 'CaseLawSummary' ? t('Add New Case Law Summaries') : ""}>
                                                                </i>
                                                                {/*<i className="uil uil-plus-circle text-primary p-2"*/}
                                                                {/*   style={{cursor: "pointer"}}*/}
                                                                {/*   data-toggle="tooltip" data-placement="bottom"*/}
                                                                {/*   title="Add New Sub-Categorie"></i>*/}
                                                            </div>
                                                        </li>
                                                    </div>
                                                    <div id={`collapseSimpleTwo${id}`}
                                                         className="accordion-collapse collapse"
                                                         aria-labelledby={`headingSimpleTwo${id}`}
                                                         data-bs-parent="#accordionSimpleExample">
                                                        <div className="card-body">
                                                            <ul className="list-unstyled fs-sm lh-sm text-reset overflow-auto">
                                                                {cat?.subtype?.map((subCat: any, id: number) => {
                                                                    return (
                                                                        <li key={id} className="pe-auto py-1">
                                                                            <a
                                                                                style={{cursor: 'pointer'}}
                                                                                className={`nav-link ${selected?.name === subCat.name && ' active'}`}
                                                                                onClick={() => handleClick(`${cat.name}_${subCat.name}`, subCat.id)}>
                                                                                {subCat.name}
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }): ''}
                            </ul>
                        </nav>
                    </div>
                </section>
            </div>
            <AddCcjaLaw
                name={type === "Category" ? t("CCJA Case Law")
                    : type === "CaseLawSummary" ? t("Case Law Summary")
                    : type === "NationalCourtCaseLaw" ? t("National Court Case Law") : ""}
                selectId={0}
                isInCcjaCaseLaw={isInCcjaCaseLaw}
                isInCaseLawSummaries={isInCaseLawSummaries}
                isInNationalCourtsCaseLaw={isInNationalCourtsCaseLaw}
                initData={(isInCcjaCaseLaw || isInNationalCourtsCaseLaw) ? {
                    ...initialCCJA,
                    categoryId: selected?.id,
                    type: isInCcjaCaseLaw ? 'CCJA-Case-Laws' :
                            isInCaseLawSummaries ? 'Case-Law-Summaries' :
                            isInNationalCourtsCaseLaw ? 'National-Court-Case-Laws' : ""
                } : isInCaseLawSummaries ?
                    {...initialCaseLaw, categoryId: selected?.id} :
                    {...initialCCJA}}
                country={isInNationalCourtsCaseLaw ? country : ""}
            />
            <AddCategory updatedData={selected} type={type} country={country}/>
            <DeleteModal name="category" id={selected?.id}
                         deleteFunction={(id: number) => {
                            type === 'Category' ? deleteCategory(id)
                                : type === 'CaseLawSummary' ? deleteCaseLawSummaryCategory(id)
                                : type === 'NationalCourtCaseLaw' && deleteNationalCourtCaseLawCategory(id)
            }}/>
            <MessageModal message={copySuccess ? t("link copied successfully") : t("Link not copied")}
                          linkCopied={linkCopied}
            />


        </>
    )
}

export default CaseLawCategoriesList
